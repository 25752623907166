import logo from './logo.svg';
import { useEffect, useRef, useState } from 'react';
import './App.css';
import { Peer } from "peerjs";
import { styled } from '@mui/material/styles';
import {Box, Typography, IconButton, Paper, TextField, Button, Stack} from '@mui/material';

import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, addDoc, getDocs, setDoc, doc } from "firebase/firestore";
import { getDatabase, ref as sRef, set } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const DATABASE_URL = 'videoCalls'

const firebaseConfig = {
  apiKey: "AIzaSyChlS1r6aURBE0u3V7DS6Q3CHY78S8LidU",
  authDomain: "miroboticproject.firebaseapp.com",
  databaseURL: "https://miroboticproject.firebaseio.com",
  projectId: "miroboticproject",
  storageBucket: "miroboticproject.appspot.com",
  messagingSenderId: "387151318489",
  appId: "1:387151318489:web:ce680b2b3088870ddc1dc8",
  measurementId: "G-G5RCBZNVQ2"
};


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {

  const username = useRef();
  const callerName = useRef();
  const peerClient = useRef();
  const localVideo = useRef();
  const remoteVideo = useRef();
  const localStream = useRef();
  const database = useRef();
  const [callStatus, setCallStatus] = useState(false);
  const [hideVideo, setHideVideo] = useState(true);
  const [muteAudio, setMuteAudio] = useState(true);
  const [initApp, setInitApp] = useState(true);

  useEffect(() => {
    if(!initApp) {
      return
    }
    database.current = getFirestore(app);
    setInitApp(false);
  }, [initApp])

  const acceptCall = () => {
    const userId = username.current;
    console.log('acceptCall', userId);
    // const db = database.current;
    // set(sRef(db, 'users/' + userId), {
    //   isAvailable: true,
    // });
  }

  async function updateUserStatusFirebase() {

    const userId = callerName.current;

    console.log('updateUserStatusFirebase', userId);
    try {
      const db = getFirestore();
      const docRef = setDoc(doc(db, DATABASE_URL, userId), {
        incoming: username.current,
        isAvailable: true,
        connId: username.current,
        time: new Date().getMilliseconds(),
        user: userId
      });
      console.log("Document written with ID: ", docRef);
    } catch (e) {
      console.error("Error adding document: ", e);
    }

  }

  async function registerFirebase() {

    const userId = username.current;

    console.log('registerFirebase', userId);

    const db = getFirestore();
    try {
      const docRef =  setDoc(doc(db, DATABASE_URL, userId), {
        incoming: "",
        isAvailable: false,
        connId: "",
        time: new Date().getMilliseconds(),
        user: userId
      });
      console.log("Document written with ID: ", docRef);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  const registerUser = () => {
    console.log('registerUser', username.current);
    if(!username.current) {
      alert('Enter username to register');
      return
    }
    console.log('registerUser', username.current);

    peerClient.current = new Peer(username.current, {
      host: 'video.mirobotic.tech',
      port: 8010,
      path: '/videocallapp',
      secure: true
    })

    listen();

    registerFirebase();
  }

  const startCall = () => {
    console.log('startCall', callerName.current);
    if(!username.current) {
      alert('Enter username to register');
      return
    }
    if(!callerName.current) {
      alert('Enter caller name to call');
      return
    }
    updateUserStatusFirebase();

    navigator.getUserMedia({
      audio: true,
      video: true
  }, (stream) => {

      localVideo.current.srcObject = stream
      localStream.current = stream

      const call = peerClient.current.call(callerName.current, stream)
      call.on('stream', (remoteStream) => {
          remoteVideo.current.srcObject = remoteStream
          remoteVideo.current.className = "primary-video"
          localVideo.current.className = "secondary-video"
          setCallStatus(true);
      })

  }, (error) => {})
  }

  function listen() {
          peerClient.current.on('call', (call) => {

          navigator.getUserMedia({
              audio: true, 
              video: true
          }, (stream) => {
              localVideo.current.srcObject = stream
              localStream.current = stream

              console.log('stream', stream);
              call.answer(stream)
              call.on('stream', (remoteStream) => {                
                  remoteVideo.current.srcObject = remoteStream
                  remoteVideo.current.className = "primary-video"
                  localVideo.current.className = "secondary-video"
                  setCallStatus(true);
                  acceptCall();
              })
          }, (error) => {})
          
      })
  }

  function toggleVideo(b) {
    console.log('toggleVideo', b);
    if (b) {
        localStream.current.getVideoTracks()[0].enabled = true
    } else {
        localStream.current.getVideoTracks()[0].enabled = false
    }
    setHideVideo(b);
  } 

  function toggleAudio(b) {
    console.log('toggleAudio', b);
    if (b) {
        localStream.current.getAudioTracks()[0].enabled = true
    } else {
        localStream.current.getAudioTracks()[0].enabled = false
    }
    setMuteAudio(b);
  } 

  const AudioStatusView = () => {
    console.log('AudioStatusView', muteAudio);
    if(!muteAudio) {
      return (
        <IconButton aria-label="audio-off" onClick={() => toggleAudio(true)}>
          <MicOffIcon />
        </IconButton>
      )
    }

    return (
      <IconButton color='primary' variant="contained" aria-label="audio-on" onClick={() => toggleAudio(false)}>
        <MicIcon />
      </IconButton>
    )
  }

  const VideoStatusView = () => {
    console.log('VideoStatusView', hideVideo);
    if(!hideVideo) {
      return (
        <IconButton aria-label="video-off" onClick={() => toggleVideo(true)}>
          <VideocamOffIcon />
        </IconButton>
      )
    }

    return (
      <IconButton color='primary' variant="contained" aria-label="video-on" onClick={() => toggleVideo(false)}>
        <VideocamIcon />
      </IconButton>
    )
  }

  const CallStatusView = () => {

    if(callStatus) {
      return (
        <Box direction="row">
            <AudioStatusView sx={{mt: 1}} />
            <VideoStatusView />
        </Box>
      )
    }

    return null
  }



  return (
    <div className="App">
      <Box>
        <Stack>
          <Item>
            <TextField id="standard-basic" label="Username" variant="standard" onChange={(e) => username.current = e.target.value} />
            <Button variant="outlined" onClick={() => registerUser()}>Register</Button>
          </Item>
          <Item>
            <TextField id="standard-basic" label="Caller name" variant="standard" onChange={(e) => callerName.current = e.target.value}/>
            <Button variant="contained" onClick={() => startCall()}>Call</Button>
          </Item>
          <Item>
            <CallStatusView />
          </Item>
          <Item>
            <Box
              sx={{
                width: 800,
                height: 500,
                backgroundColor: 'primary.dark',
                '&:hover': {
                  backgroundColor: 'primary.main',
                  opacity: [0.9, 0.8, 0.7],
                },
              }}>
              <video className="secondary-video" ref={remoteVideo} autoPlay id="remote-video"></video>
              <video className="primary-video" ref={localVideo} autoPlay muted id="local-video"></video>
            </Box>
          </Item>
        </Stack>
      </Box>
    </div>
  );
}

export default App;
